import { COUNTRIES_CODES } from '@wix/forms-common'
import { getCountryCodeByGEO } from './utils'

export { COUNTRIES_CODES }

export const DEFAULT_COUNTRY_CODE = getCountryCodeByGEO('US')
/**
 * The ITU says the maximum length should be 15, but there are longer
 * numbers in Germany.
 */
export const DEFAULT_PHONE_NUMBER_VALIDATION_REGEX = '^([(][0-9]{1,3}[)][-]?)?([0-9][-]?){3,16}[0-9]$'

export type CountriesCodesKeys = keyof typeof COUNTRIES_CODES
